<template>
  <div class="main">
    <h1>欢迎来到会员后台系统</h1>
  </div>
</template>
<script>
// import Mock from 'mockjs'
import { articleList } from "@/api/data.js";
import router from "@/router"; //路由
import Router from "vue-router";
import { login, getPermissionsList } from "../../api/data";
export default {
  name: "Login",
  data() {
    return {};
  },
  mounted() {
    this.search();
  },
  methods: {
    search() {
      articleList({
        page: this.page,
        limit: 10,
      }).then(({ data: res }) => {
        if (res.code === 200) {
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 95%;
  // height: 95%;
  line-height: 400px;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  background-image: -webkit-linear-gradient(right, #793a98, #687ac8, #56baf9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>